.icon-X_20px { background-image: url('../icons/png/X_20px.png'); background-repeat: no-repeat; }

.icon-close-1 { background-image: url('../icons/png/close-1.png'); background-repeat: no-repeat; }

.icon-close-red { background-image: url('../icons/png/close-red.png'); background-repeat: no-repeat; }

.primary-nav__close,
.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close .ui-icon,
.icon-close-white { background-image: url('../icons/png/close-white.png'); background-repeat: no-repeat; }

.icon-close { background-image: url('../icons/png/close.png'); background-repeat: no-repeat; }

.icon-email_20px { background-image: url('../icons/png/email_20px.png'); background-repeat: no-repeat; }

a.external span::after,
.icon-external { background-image: url('../icons/png/external.png'); background-repeat: no-repeat; }

.icon-facebook { background-image: url('../icons/png/facebook.png'); background-repeat: no-repeat; }

.icon-facebook_20px { background-image: url('../icons/png/facebook_20px.png'); background-repeat: no-repeat; }

.icon-instagram-white { background-image: url('../icons/png/instagram-white.png'); background-repeat: no-repeat; }

.icon-instagram { background-image: url('../icons/png/instagram.png'); background-repeat: no-repeat; }

.icon-instagramwhite { background-image: url('../icons/png/instagramwhite.png'); background-repeat: no-repeat; }

a.field--name-field-sub-headline::after,
.icon-large-right-arrow-white { background-image: url('../icons/png/large-right-arrow-white.png'); background-repeat: no-repeat; }

.icon-large-right-arrow { background-image: url('../icons/png/large-right-arrow.png'); background-repeat: no-repeat; }

.icon-linkedin { background-image: url('../icons/png/linkedin.png'); background-repeat: no-repeat; }

.icon-linkedin_20px { background-image: url('../icons/png/linkedin_20px.png'); background-repeat: no-repeat; }

.js input.form-autocomplete.ui-autocomplete-loading.ui-autocomplete-loading,
.icon-load-spinner { background-image: url('../icons/png/load-spinner.png'); background-repeat: no-repeat; }

.burger-button__icon,
.icon-menu-white { background-image: url('../icons/png/menu-white.png'); background-repeat: no-repeat; }

.icon-menu { background-image: url('../icons/png/menu.png'); background-repeat: no-repeat; }

.icon-play { background-image: url('../icons/png/play.png'); background-repeat: no-repeat; }

.icon-print { background-image: url('../icons/png/print.png'); background-repeat: no-repeat; }

.icon-scroll { background-image: url('../icons/png/scroll.png'); background-repeat: no-repeat; }

.block--views-exposed-filter-blocksearch-page-1 .form-submit,
.block--hiscox-search.search-form .form-submit,
.paragraph--type--in-body-search .form-submit,
.icon-search-white { background-image: url('../icons/png/search-white.png'); background-repeat: no-repeat; }

.icon-search { background-image: url('../icons/png/search.png'); background-repeat: no-repeat; }

.bellows__header::after,
.icon-small-down-arrow-0 { background-image: url('../icons/png/small-down-arrow-0.png'); background-repeat: no-repeat; }

.icon-small-down-arrow-black { background-image: url('../icons/png/small-down-arrow-black.png'); background-repeat: no-repeat; }

.form__item--type-uniform span,
.icon-small-down-arrow { background-image: url('../icons/png/small-down-arrow.png'); background-repeat: no-repeat; }

.slick-arrow::before,
.pager__navigation-link--next::before,
.pager__navigation-link--prev::before,
.blog-filters__heading::before,
.icon-small-right-arrow-black { background-image: url('../icons/png/small-right-arrow-black.png'); background-repeat: no-repeat; }

.back-to-previous::before,
.icon-small-right-arrow-gray { background-image: url('../icons/png/small-right-arrow-gray.png'); background-repeat: no-repeat; }

.is-dark a.button,
.pager__navigation-link--next:hover::before,
.pager__navigation-link--next:focus::before,
.pager__navigation-link--prev:hover::before,
.pager__navigation-link--prev:focus::before,
.icon-small-right-arrow-red { background-image: url('../icons/png/small-right-arrow-red.png'); background-repeat: no-repeat; }

.button,
.header-link a,
#popup-message-window .btn,
.header--narrow .primary-nav__panel .menu__item > span::before,
.node--view-mode-header .slick-arrow::before,
.sub-menu__button--open,
.node--type-ppc-landing-page .text-col a.link.cta-callback,
.icon-small-right-arrow-white { background-image: url('../icons/png/small-right-arrow-white.png'); background-repeat: no-repeat; }

.icon-small-right-arrow { background-image: url('../icons/png/small-right-arrow.png'); background-repeat: no-repeat; }

.icon-small-up-arrow-0 { background-image: url('../icons/png/small-up-arrow-0.png'); background-repeat: no-repeat; }

.icon-small-up-arrow-black { background-image: url('../icons/png/small-up-arrow-black.png'); background-repeat: no-repeat; }

.icon-small-up-arrow { background-image: url('../icons/png/small-up-arrow.png'); background-repeat: no-repeat; }

.form__item--type-checkbox .form-checkbox:checked + label::after,
.icon-tick-red { background-image: url('../icons/png/tick-red.png'); background-repeat: no-repeat; }

.icon-tick { background-image: url('../icons/png/tick.png'); background-repeat: no-repeat; }

.icon-twitter { background-image: url('../icons/png/twitter.png'); background-repeat: no-repeat; }

.icon-whatsapp_20px { background-image: url('../icons/png/whatsapp_20px.png'); background-repeat: no-repeat; }

.icon-youtube { background-image: url('../icons/png/youtube.png'); background-repeat: no-repeat; }

